document.addEventListener("DOMContentLoaded", () => {
  const init = () => {
    // Append the "Read less" link after each case description (hidden on page load)
    const readLessLinkTemplate = document.getElementById("read-less-template");
    const clonedLink = readLessLinkTemplate.cloneNode(true);
    clonedLink.classList.remove("text-hide");
    clonedLink.removeAttribute("id");

    document
      .querySelectorAll(".case .full-text p:last-of-type")
      .forEach((paragraph) => {
        paragraph.insertAdjacentHTML("beforeend", "&nbsp;&nbsp;");
        paragraph.appendChild(clonedLink.cloneNode(true));
      });

    readLessLinkTemplate.remove();
  };

  // Scroll to the target section when a nav link is clicked
  document.querySelectorAll(".nav-link[href^='#']").forEach((link) => {
    link.addEventListener("click", (e) => {
      e.preventDefault();
      const scrollTarget = document.querySelector(
        e.currentTarget.getAttribute("href")
      );
      const scrollTo = Math.max(0, scrollTarget.offsetTop);
      window.scrollTo({ top: scrollTo, behavior: "smooth" });
    });
  });

  // Close mobile hamburger menu when a link is clicked
  document.querySelectorAll("#mobile-menu a").forEach((link) => {
    link.addEventListener("click", () => {
      const mobileMenuCheckbox = document.querySelector("#mobile-menu input");
      mobileMenuCheckbox.checked = false;
    });
  });

  document.querySelectorAll(".case").forEach((caseElement) => {
    caseElement.addEventListener("click", (e) => {
      if (e.target.matches(".read-more")) {
        e.preventDefault();
        const fullTextWrapper = caseElement.querySelector(".full-text");
        const chapterCount = fullTextWrapper.querySelectorAll("p").length;
        const maxHeight = Math.max(300, chapterCount * 300);
        fullTextWrapper.style.maxHeight = maxHeight + "px";

        e.target.style.opacity = 0;
      }

      if (e.target.matches(".read-less")) {
        e.preventDefault();
        const fullTextWrapper = caseElement.querySelector(".full-text");
        fullTextWrapper.style.maxHeight = "0";

        // Show the "Read more" link after a 0.5s delay, after the text slide animation is done
        setTimeout(() => {
          const readMoreLink = caseElement.querySelector(".read-more");
          readMoreLink.style.opacity = 1;
        }, 500);
      }
    });
  });

  init();
});

// Make the Google Maps initialization function available globally
window.initMap = () => {
  if (!google || !google.maps) {
    console.log("Google Maps API not present");
    return;
  }

  const companyLatLong = new google.maps.LatLng(60.1795, 24.949);

  const mapOptions = {
    center: companyLatLong,
    zoom: 16,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    mapId: "DEMO_MAP_ID",
  };

  const map = new google.maps.Map(
    document.getElementById("company-map"),
    mapOptions
  );

  new google.maps.marker.AdvancedMarkerElement({
    position: companyLatLong,
    map: map,
    title: "Acelvia Oy",
  });
};
